<template>
  <v-container id="chatWrapper" ref="chatWrapper" fluid style="padding: 0;">
    <div class="chat-container" ref="chatContainer" @scroll="onScroll">
      <message
        v-for="message in messages"
        :key="message.idMensaje"
        :message="message"
        @imageLoad="scrollToEnd"
        @reload="getMessages"
      ></message>

      <v-fab-transition>
        <v-btn
          v-show="progress > 0.1"
          color="primary"
          fab
          dark
          small
          absolute
          bottom
          right
          @click.stop="scrollToEnd"
        >
          <v-icon>mdi-arrow-down-thick</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>

    <message-input
      ref="messageInput"
      :idEstudio="idEstudio"
      @reload="getMessages"
    />
  </v-container>
</template>

<script>
import pallete from "@/utils/colorPallete.js";
import "viewerjs/dist/viewer.css";
// import { useWebSocket } from "@/services/useWebSocket.js";

export default {
  props: {
    // messages: Array,
    idEstudio: Number | String,
  },
  components: {
    Message: () => import("./Message.vue"),
    MessageInput: () => import("./MessageInput.vue"),
  },
  data() {
    return {
      progress: 0,
      socket: null,
      messages: [],
      polling: null,
    };
  },
  beforeDestroy() {
    clearInterval(this.polling)
  },
  mounted() {
    const dropzone = this.$refs.chatWrapper;

    for (let i = 0; i < this.messages.length; i += 1) {
      this.messages[i].userColor =
        pallete[
          ((this.messages[i].idUsuario % pallete.length) + pallete.length) %
            pallete.length
        ];
    }

    dropzone.addEventListener("dragenter", (e) => {
      e.preventDefault();
      this.$refs.messageInput.message.showFiles = true;
    });

    // this.socket = useWebSocket( process.env.VUE_APP_API_URL, `/chat/${this.idEstudio}` );
    this.getMessages();


    this.polling = setInterval(() => {
      this.getMessages();
    }, 1000 * 60 * 1.5)

  },
  methods: {
    scrollToEnd() {
      this.$nextTick(() => {
        var container = this.$el.querySelector(".chat-container");
        container.scrollTop = container.scrollHeight;
      });
    },
    scrollTo() {
      this.$nextTick(() => {
        let currentHeight = this.$refs.chatContainer.scrollHeight;
        let difference = currentHeight - this.totalChatHeight;
        var container = this.$el.querySelector(".chat-container");
        container.scrollTop = difference;
      });
    },
    onScroll() {
      const progress = Math.abs(
        this.$refs.chatContainer.scrollTop /
          (this.$refs.chatContainer.scrollHeight -
            this.$refs.chatContainer.clientHeight)
      );
      if (progress > 1) {
        this.progress = 1;
      } else if (progress < 0) {
        this.progress = 0;
      } else {
        this.progress = progress;
      }
    },
    async getMessages() {
      const { data : messages } = await axios({ url : `${process.env.VUE_APP_API_URL}/chat/${ this.idEstudio }` })

      this.messages = messages.map( x => ({ 
        ...x.Mensaje, 
				userColor: pallete[ ((x.Mensaje.idUsuario % pallete.length) + pallete.length) % pallete.length ],
        leido : x.leido, 
        leidoPorComercial : x.leidoPorComercial 
      }));

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/chat/leidos`,
        data: {
          mensajes: this.messages
            .filter((m) => !Number(m.leido))
            .map((m) => m.idMensaje),
        },
      });

      this.$root.$emit("reloadMensajesSinLeer");

    }
  },
  sockets: {
    "send-message": function(data) {
      this.messages.push(data);
      this.$nextTick(() => {
        let objDiv = this.$refs.messages;
        objDiv.scrollTop = objDiv.scrollHeight;
      });
    },
    "reload-messages": function() {
      this.getMessages();
    },
    typing: function({ typing, user }) {
      if (typing) {
        this.typing.push(user);
      } else {
        this.typing.splice(
          this.typing.findIndex((u) => u.id == user.id),
          1
        );
      }
      this.$nextTick(() => {
        let objDiv = this.$refs.messages;
        objDiv.scrollTop = objDiv.scrollHeight;
      });
    },
    "delete-message": function(id) {
      this.messages.splice(
        this.messages.findIndex((m) => m._id == id),
        1
      );
    },
  },
  // updated() {
  //   this.scrollToEnd()
  // }
};
</script>

<style>
.chat-container {
  box-sizing: border-box;
  min-height: 200px;
  max-height: 600px;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
  background-color: #e4e4e4;
}
.theme--dark .chat-container {
  background-color: #272727;
}
</style>
